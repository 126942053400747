import { template as template_fe906a434b714d52acd34017f1bfe7a3 } from "@ember/template-compiler";
const FKText = template_fe906a434b714d52acd34017f1bfe7a3(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
