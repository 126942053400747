import { template as template_78f3eaceefec4dca9a6690e4d551b7c5 } from "@ember/template-compiler";
const WelcomeHeader = template_78f3eaceefec4dca9a6690e4d551b7c5(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
